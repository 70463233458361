class ZIDXAccountPaymentPlans implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountPaymentPlansContainer";
    }
    getMatchURL(){
        return "/account/payment-plans";
    }
    render(){
        // console.log("payment plans");
    }
}